import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { OrderDetailStore } from './store';
import styles from './index.less';
import { Popover } from 'antd';

export const ORDER_INFO = [
  {
    label: '订单编号',
    key: 'mallSaleOrderNo',
  },
  {
    label: '来源单号',
    key: 'platformOrderNo',
  },
  {
    label: '来源店铺',
    key: 'platformShopName',
  },
  {
    label: '来源平台',
    key: 'platformTypeDesc',
  },
  {
    label: '发货网仓',
    key: 'deliverWarehouseName',
  },
  {
    label: '',
    key: '',
  },
  {
    label: '',
    key: '',
  },
  {
    label: '',
    key: '',
  },
  {
    label: '下单时间',
    key: 'createdTime',
  },
  {
    label: '付款时间',
    key: 'payTime',
  },
  {
    label: '支付方式',
    key: 'payType',
  },
  {
    label: '',
    key: '',
  },
  {
    label: '留言内容',
    key: 'message',
  },
];

export const RECEIVE_INFO = [
  {
    label: '收货人',
    key: 'receiverName',
  },
  {
    label: '联系方式',
    key: 'receiverMobile',
  },
  {
    label: '联系地址',
    key: 'receiverAddress',
  },
];

export const PRODUCT_COLUMNS = (context: OrderDetailStore) => {
  return [
    {
      key: '',
      width: 240,
      name: '商品信息',
      formatter: ({ row }) => {
        return (
          <div className={styles.productInfoWrapper}>
            <Popover content={(
              <div className={styles.imgWrapPopover}>
                <img
                  height={200}
                  src={row.skuPic}
                  width={200}
                />
              </div>
            )}
            >
              <div className={styles.imgWrap}>
                <img
                  alt=""
                  height={56}
                  src={row.skuPic}
                  width={56}
                />
              </div>
            </Popover>
            <div className={styles.productInfo}>
              <div>
                {row.goodsName}
              </div>
              <div>
                货号：
                {row.sellerOuterNo}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: '商品规格',
      key: 'goodsSpec',
      width: 80,
    },
    {
      name: '明细状态',
      key: 'detailOrderStatusName',
    },
    {
      name: '单价（元）',
      key: 'price',
    },
    {
      name: '下单数量',
      key: 'originNum',
    },
    {
      name: '实发数量',
      key: 'num',
    },
    {
      name: '售后状态',
      key: 'returnOrderStatus',
      formatter: ({ row }) => {
        return (
          <div className={styles.afterSaleColumn}>
            <div>
              {row.returnOrderStatus}
            </div>
            <div>
              {row.returnOrderNo && (
                <a
                  onClick={() => {
                    window.top.egenie.openTab(`/egenie-ts-vogue/afterSaleOrder/index?returnOrderNo=${row.returnOrderNo}`, 754, '售后管理');
                  }}
                >
                  售后详情
                </a>
              )}
            </div>
          </div>
        );
      },
    },
    {
      name: '小计',
      key: 'totalPrice',
      formatter: ({ row }) => {
        return (
          <div>
            ¥
            {row.totalPrice}
          </div>
        );
      },
    },
  ];
};

export const payTypeDict = {
  '1': '余额',
  '2': '微信',
  '3': '支付宝',
};
