import type { BaseData } from 'egenie-common';
import { EgGridModel, request } from 'egenie-utils';
import { action, flow, observable } from 'mobx';
import type { ICourierVo } from '../../afterSale/afterSaleDetail/types';
import { api } from '../api';
import type { IDetailInfo } from '../interface';
import { PRODUCT_COLUMNS } from './constant';

export class OrderDetailStore {
  constructor() {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const id = search.get('mallSaleOrderId');
    this.toggleDetailModal(id);
  }

  @observable public loading = false;

  @observable public tabKey = '1';

  @observable public detailInfo: IDetailInfo;

  @observable public courierInfoVos: ICourierVo[] = [];// 物流信息
  
  public productGridModel = new EgGridModel({
    columns: PRODUCT_COLUMNS(this).map((item) => ({
      ...item,
      resizable: true,
    })),
    rows: [],
    rowHeight: 66,
    primaryKeyField: 'goodsSkuNo',
    showCheckBox: false,
    showPager: false,
  });

  @action public onTabChange = (key: string) => {
    this.tabKey = key;
  };

  @action public toggleDetailModal = flow(function* (id?: string) {
    this.loading = true;
    try {
      const detailRes = yield request<BaseData<IDetailInfo>>({
        url: api.getOrderDetail,
        method: 'GET',
        params: { id },
      });

      this.detailInfo = detailRes.data;
      this.productGridModel.rows = detailRes.data?.detailPcVos || [];
      const courierRes = yield request<BaseData>({
        url: api.getCourierInfo,
        method: 'GET',
        params: { mallSaleOrderId: id },
      });
      this.courierInfoVos = courierRes.data;
    } catch (e) {
      console.log('请求接口出错', e);
    } finally {
      this.loading = false;
    }
  });
}
