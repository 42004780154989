import { Col, Descriptions, Row, Spin, Tabs, Tooltip, Typography } from 'antd';
import { toFixed } from 'egenie-common';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import noData from '../../../assets/images/noData.png';
import { Courier } from '../../afterSale/afterSaleDetail/courier';
import { CollapsePanel } from '../../components';
import { ORDER_INFO, payTypeDict, RECEIVE_INFO } from './constant';
import styles from './index.less';
import { StepStatus } from './stepStatus';
import { OrderDetailStore } from './store';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const detailStore = new OrderDetailStore();

const OrderDetail: React.FC<{ store: OrderDetailStore; }> = observer(() => {
  const { loading } = detailStore;
  return (

    <Spin spinning={loading}>
      <div className={styles.page}>
        <StepStatus store={detailStore}/>
        <OrderInfo store={detailStore}/>
        <ReceiveInfo store={detailStore}/>
        <SendInfo store={detailStore}/>
        <ProductInfo store={detailStore}/>
      </div>
    </Spin>
  );
});

const { Item } = Descriptions;

// 订单信息
const OrderInfo: React.FC<{ store: OrderDetailStore; }> = (props) => {
  const { detailInfo } = props.store;
  return (
    <CollapsePanel title="订单信息">
      <Descriptions column={4}>
        {
          ORDER_INFO.map((item) => (
            <Item
              key={nanoid()}
              label={item.label}
            >
              {(detailInfo && detailInfo[item.key]) ? (item.label === '支付方式' ? payTypeDict[detailInfo[item.key]] : detailInfo[item.key]) : ''}
              {
                (detailInfo?.returnOrderIds?.length > 0 && item.label === '售后状态') && (
                  <a
                    className={styles.afterSaleDetail}
                    onClick={() => {
                      window.top.egenie.openTab(`/egenie-ts-vogue/afterSaleOrder/index?saleOrderNo=${detailInfo.mallSaleOrderNo}`, 754, '售后管理');
                    }}
                  >
                    查看售后详情
                    {' >'}
                  </a>
                )
              }
            </Item>
          ))
        }
      </Descriptions>
    </CollapsePanel>
  );
};

// 发货信息
const ReceiveInfo: React.FC<{ store: OrderDetailStore; }> = (props) => {
  const { detailInfo } = props.store;
  return (
    <CollapsePanel title="收货信息">
      <Descriptions column={4}>
        {
          RECEIVE_INFO.map((item) => (
            <Item
              key={nanoid()}
              label={item.label}
            >
              {(detailInfo && detailInfo[item.key]) || ''}
            </Item>
          ))
        }
      </Descriptions>
    </CollapsePanel>
  );
};

const SendInfo: React.FC<{ store: OrderDetailStore; }> = observer((props) => {
  const {
    tabKey,
    onTabChange,
    detailInfo,
    courierInfoVos,
  } = props.store;
  const items = Array.isArray(courierInfoVos) && courierInfoVos.length > 0 && courierInfoVos.map((item, index) => {
    return {
      key: `${index + 1}`,
      label: `包裹${index + 1}`,
      children: <Courier courierInfoVos={item}/>,
    };
  }
  );
  return (
    <CollapsePanel title="发货信息">
      {
        (!detailInfo || !detailInfo.courierInfoVos || !detailInfo.courierInfoVos?.length) ? (
          <div className={styles.noDataWrapper}>
            <img
              className={styles.noData}
              src={noData}
            />
            <div className={styles.noDataInfo}>
              暂无发货信息！
            </div>
          </div>
        )
          : (
            <Tabs
              activeKey={tabKey}
              items={items}
              onChange={onTabChange}
            />
          )
      }
    </CollapsePanel>
  );
});

const { Text } = Typography;
const ProductInfo: React.FC<{ store: OrderDetailStore; }> = (props) => {
  const {
    productGridModel,
    detailInfo,
  } = props.store;
  const renderItem = () => {
    return detailInfo?.preBillingCostBaseVos?.map(({
      productName,
      amount,
    }) => {
      return (
        <div>
          <Text className={styles.span}>
            {productName}
            ：
          </Text>
          <Text>
            ¥
            {' '}
            {toFixed(Number(amount || 0), 2)}
          </Text>
        </div>
      );
    });
  };
  return (
    <CollapsePanel title="商品信息">
      <div style={{ height: '170px' }}>
        <EgGrid store={productGridModel}/>
      </div>
      <Row
        className={styles.summary}
        justify="end"
      >
        <Col span={4}>
          <div>
            <Text className={styles.span}>
              商品总金额：
            </Text>
            <Text>
              ¥
              {' '}
              {toFixed(Number(detailInfo?.goodsAmount || 0), 2)}
            </Text>
          </div>
          <div>
            <Text className={styles.span}>
              优惠金额：
            </Text>
            <Text>
              ¥
              {' '}
              {toFixed(Number(detailInfo?.discountAmount || 0), 2)}
            </Text>
          </div>
          <div>
            <Text className={styles.span}>
              运费：
            </Text>
            <Text>
              ¥
              {' '}
              {toFixed(Number(detailInfo?.postAmount || 0), 2)}
            </Text>
          </div>
          <div>
            <Text className={styles.span}>
              平台服务费：
            </Text>
            <Text>
              ¥
              {' '}
              {toFixed(Number(detailInfo?.financeServiceAmount || 0), 2)}
            </Text>
          </div>
          <div>
            <Text className={styles.span}>
              增值服务费：
            </Text>
            <Text>
              ¥
              {' '}
              {toFixed(Number(detailInfo?.valueAddServiceAmount || 0), 2)}
            </Text>
            <Tooltip
              color="#fff"
              overlayInnerStyle={{ color: '#2b2e3e' }}
              placement="top"
              title={() => {
                return (
                  <div>
                    {detailInfo.preBillingRecordDetailInfoVos.map(({
                      valueAddedTypeName,
                      materialName,
                      amount,
                    }) => {
                      return (
                        <div className={styles.detailContainer}>
                          <span>
                            {valueAddedTypeName}
                            ：
                            {materialName}
                          </span>
                          <span className={styles.ml16}>
                            ￥
                            {amount.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
              trigger="click"
            >
              <ExclamationCircleOutlined className={styles.icon}/>
            </Tooltip>
          </div>
          {renderItem()}
          <div className={styles.eachSummary}>
            <Text className={styles.span}>
              实付金额：
            </Text>
            <Text>
              <span className={styles.payment}>
                ¥
                {' '}
                {detailInfo?.orderStatus === 0 ? 0 : toFixed(Number(detailInfo?.actualAmount || 0), 2)
                  .toLocaleString()}
              </span>
            </Text>
          </div>
        </Col>
      </Row>
    </CollapsePanel>
  );
};

export default OrderDetail;
