import { Steps } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import styles from './index.less';
import type { OrderDetailStore } from './store';

const { Step } = Steps;

const stepsGroup = [
  {
    name: '买家下单',
    icon: 'icon-bzdgl',
    timeName: 'createdTime',
  },
  {
    name: '付款成功',
    icon: 'icon-d_finance',
    timeName: 'payTime',
  },
  {
    name: '卖家发货',
    icon: 'icon-register_purchaser',
    timeName: 'deliverTime',
  },
  {
    name: '交易完成',
    icon: 'icon-gysdhhsbb',
    timeName: 'receiverTime',
  },
];

const closeStepsGroup = [
  {
    name: '买家下单',
    icon: 'icon-bzdgl',
    timeName: 'createdTime',
  },
  {
    name: '付款成功',
    icon: 'icon-d_finance',
    timeName: 'payTime',
  },
  {
    name: '交易关闭',
    icon: 'icon-btn_zf',
    timeName: 'cancelTime',
  },
];

export const StepStatus: React.FC<{
  store: OrderDetailStore;
}> = observer((props) => {
  const { detailInfo } = props.store;
  return (
    <div className={styles.stepWrapper}>
      {
        detailInfo?.orderStatus === 4 ? (
          <div style={{ margin: '0px 300px' }}>
            <Steps>
              {closeStepsGroup.map((item) => {
                return (
                  (detailInfo && detailInfo[item.timeName]) && (
                    <Step
                      description={detailInfo[item.timeName]}
                      icon={<i className={item.icon}/>}
                      key={nanoid()}
                      status="finish"
                      title={item.name}
                    />
                  )
                );
              })}
            </Steps>
          </div>
        ) : (
          <div style={{ margin: '0px 132px' }}>
            <Steps>
              {stepsGroup.map((item, index) => {
                return (
                  <Step
                    description={(detailInfo && detailInfo[item.timeName]) || ''}
                    icon={<i className={item.icon}/>}
                    key={nanoid()}
                    status={(detailInfo && detailInfo[item.timeName]) ? 'finish' : 'wait'}
                    title={item.name}
                  />
                );
              })}
            </Steps>
          </div>
        )
      }
      <BannerElement
        countDownTime={detailInfo?.countDownTime}
        orderStatus={detailInfo?.orderStatus}
      />
    </div>
  );
});

const BannerElement: React.FC<{
  orderStatus: number;
  countDownTime?: string;
}> = observer((props) => {
  const { orderStatus, countDownTime } = props;
  return (
    <div className={styles.banner}>
      {
        orderStatus === 0 && (
          <>
            <div className={styles.bannerState}>
              买家已提交订单，等待买家付款
            </div>
            {countDownTime !== '0分0秒' ? (
              <div className={styles.timeLimit}>
                （买家如在
                {countDownTime}
                内没有完成付款，交易自动关闭）
              </div>
            ) : null}
          </>
        )
      }
      {

        // 拿货中和已付款tip一样
        (orderStatus === 1 || orderStatus === 5) && (
          <div className={styles.bannerState}>
            买家已付款，等待卖家发货
          </div>
        )
      }
      {
        orderStatus === 2 && (
          <>
            <div className={styles.bannerState}>
              卖家已发货，等待买家签收
            </div>
            <div className={styles.timeLimit}>
              （买家如在平台规定时间内没有申请退款或确认收货，交易将自动完成）
            </div>
          </>
        )
      }
      {
        orderStatus === 3 && (
          <div className={styles.bannerState}>
            买家已签收，交易完成
          </div>
        )
      }
      {
        orderStatus === 4 && (
          <div className={styles.bannerState}>
            交易已关闭
          </div>
        )
      }
    </div>
  );
});

export default StepStatus;
