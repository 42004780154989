import { CheckCircleOutlined } from '@ant-design/icons';
import { Tabs, Timeline, Descriptions, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { ICourierVo } from '../types';
import styles from './index.less';

const labelStyle = {
  width: '90px',
  justifyContent: 'flex-end',
};

const { Item } = Descriptions;
export const Courier: React.FC<{ courierInfoVos: ICourierVo; }> = observer((props) => {
  const { courierInfoVos } = props;
  return (
    <div className={styles.eachCourier}>
      <Descriptions
        className={styles.wid300}
        column={1}
      >
        <Item
          label="物流公司"
          labelStyle={labelStyle}
        >
          <span>
            {courierInfoVos?.courierName}
          </span>
        </Item>
        <Item
          label="运单号"
          labelStyle={labelStyle}
        >
          <span>
            {courierInfoVos?.courierOrderNo}
          </span>
        </Item>
        <Item
          label="发货时间"
          labelStyle={labelStyle}
        >
          <span>
            {courierInfoVos?.deliverGoodsTime}
          </span>
        </Item>
        <Item
          label="商品信息"
          labelStyle={labelStyle}
          span={4}
        >
          <div>
            <span>
              {`共${courierInfoVos?.totalNum}件`}
            </span>
            <div className={styles.images}>
              <Row gutter={[
                8,
                8,
              ]}
              >
                {courierInfoVos?.detailVoList.map((el) => {
                  return (
                    <Col key={nanoid()}>
                      <span className={styles.imgContainer}>
                        <img
                          className={styles.img}
                          src={el.pic}
                        />
                        <div className={styles.styleNum}>
                          {`${el.num}件`}
                        </div>
                      </span>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </Item>
      </Descriptions>
      {
        courierInfoVos?.courierDetailListVoList && Boolean(courierInfoVos?.courierDetailListVoList.length) && (
          <div className={styles.timeLineWrapper}>
            <div className={styles.timeline}>
              <div className={styles.timeTitle}>
                物流信息
              </div>
              <Timeline mode="left">
                {courierInfoVos?.courierDetailListVoList?.map((item) => {
                  if (Number(item.statusCode) === 4) {
                    return (
                      <Timeline.Item
                        dot={(
                          <CheckCircleOutlined
                            style={{ fontSize: '16px' }}
                          />
                        )}
                        key={nanoid()}
                        label={item?.statusTime}
                      >
                        <p className={styles.signed}>
                          已签收
                        </p>
                        <p>
                          {item.trace}
                        </p>
                      </Timeline.Item>
                    );
                  }
                  return (
                    <Timeline.Item
                      color="gray"
                      key={nanoid()}
                      label={item?.statusTime}
                    >
                      {item.trace}
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
          </div>
        )
      }
    </div>
  );
});
