import { Collapse } from 'antd';
import React from 'react';
import styles from './index.less';

const { Panel } = Collapse;

// 收缩折叠面板
export const CollapsePanel: React.FC<{ title: string; }> = (props) => {
  const { children, title } = props;
  return (
    <Collapse
      bordered={false}
      className={styles.collapse}
      defaultActiveKey="1"
      ghost
    >
      <Panel
        header={title}
        key="1"
      >
        {children}
      </Panel>
    </Collapse>
  );
};
