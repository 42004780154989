export const api = {
  queryOrderList: '/api/mall/rest/saleOrder/pageOrder', // 查询订单列表
  queryStatusList: '/api/mall/rest/saleOrder/status', // 查询订单状态列表
  getUsers: '/api/mall/rest/saleOrder/getUsers', // 获取下单账号
  getCourierInfo: '/api/mall/rest/saleOrder/findCourierOrder', // 获取物流信息
  cancelOrder: '/api/mall/rest/saleOrder/cancelOrder', // 取消订单
  confirmReceive: '/api/mall/rest/saleOrder/confirmReceive', // 确认收货
  getOrderDetail: '/api/mall/rest/saleOrder/findDetail', // 查询订单详情
  getVendorList: '/api/mall/rest/saleOrder/getSimpleVendorInfo', // 获取档口信息
  buyAgain: '/api/mall/rest/shoppingCart/buyAgain', // 再次购买
  batchCancelOrder: '/api/mall/rest/saleOrder/batch/cancel/order', // 批量取消订单
};
